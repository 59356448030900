#root {
  .ant-layout-sider,
  .ant-layout-sider-trigger,
  .ant-menu {
    background: #3f4d67;
  }
  .ant-layout-header {
    background-color: #fff;
  }
  .ant-menu,
  .ant-menu-item-selected,
  .ant-menu-item a {
    color: #fff;
  }
  .ant-menu-item a:hover {
    color: #1d91fc;
  }
  .ant-menu-item-selected a:hover {
    color: #fff;
  }
  .ant-menu-item:hover {
    background-color: #3a465e;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1d91fc;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0;
  }
  .ant-menu-inline .ant-menu-item {
    width: 100%;
  }
}

button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation:none 0 ease 0 1 normal;
}
.ant-form-item-explain .ant-form-item-explain-error:nth-child(2) {
  display: none;
}