@import "styles/theme.scss";

@mixin flex-justify($content: center) {
  display: flex;
  align-items: center;
  justify-content: $content;
}

@mixin set-font($size: 16px, $color: $colorText, $line: 1, $weight: 400) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
  line-height: $line;
}

@mixin flex-all($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}
