$colorPrimary: #f32735;
// icon 颜色
$colorIcon: #999ba4;
$colorIconHover: #73747d;
$colorIconMenuHover: #272e4d;

// 文字颜色
$colorText: #4c4c4c;

// 背景色
$colorBg: #fff;
$colorBgHover: #f4f5f6;

// border颜色
$colorBorderLayout: rgba(199, 201, 211, 0.5);
// 蒙层颜色
$colorMask: rgba(0, 0, 0, 0.6);

$colorAlertSuccess: rgba(82, 196, 26, 0.5);
$colorAlertWarn: rgba(255, 168, 0, 0.5);
$colorAlertError: rgba(255, 42, 36, 0.5);
$colorAlertSuccessBg: #f4fbf1;
$colorAlertWarnBg: #fffaef;
$colorAlertErrorBg: #ffeeee;
$colorAlertSuccessIcon: #52c41a;
$colorAlertWarnIcon: #ffa800;
$colorAlertErrorIcon: #ff2a24;

//字体 family
$OpenSansMedium: OpenSans-SemiBold;
$OpenSans: OpenSans;
$OpenSansBold: OpenSans-Bold;
$Quicksand: Quicksand;
$QuicksandBold: Quicksand-Bold;

//屏幕尺寸
$screenPc: 1192px;
$screenPcMax: 1980px;
$screenPhone: 375px;
$screenPad: 768px;
