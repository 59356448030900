@import "~antd/dist/antd.css";
@import "styles/reset.scss";
@import "styles/common.scss";
@import "styles/antd.scss";

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1192px) {
html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  min-width: 1192px;
  overflow-y: hidden;
  overflow-x: auto;
}
}
@media (max-width: 768px) {
  html,
  body,
  #root,
  .App {
    width: 100%;
    height: 100%;
  }

}
