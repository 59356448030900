@import "styles/mixin.scss";

.wrap {
  height: 100%;
  .content {
    text-align: center;
    img {
      width: 400px;
      height: 364px;
    }
    .title {
      margin: 10px auto;
      @include set-font(18px, rgba(0, 0, 0, 0.85), 22px, 500);
    }
    .des {
      @include set-font(14px, rgba(0, 0, 0, 0.65), 22px);
    }
  }
}
