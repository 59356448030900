.layout_wrap {
  height: 100%;
  overflow: hidden;
  .logo {
    height: 84px;
    text-align: center;
  }
  .logo_img {
    margin-top: 20px;
    margin-bottom: 8px;
    width: 99px;
    height: 20px;
  }
  .logo_img_s {
    margin-top: 30px;
    width: 22px;
    height: 22px;
  }

  .title {
    font-size: 14px;
    text-align: center;
    color: rgba(255, 255, 255, 0.85);
  }

  .content {
    height: calc(100% - 70px);
    padding: 14px 24px;
    overflow-y: hidden;
    > div {
      height: 100%;
      background-color: #fff;
      padding: 24px;
      border-radius: 2px;
      overflow-y: auto;
    }
  }
  .img {
    width: 18px;
    height: 18px;
  }
}

.head_wrap {
  cursor: pointer;
  text-align: right;
  padding: 0 24px;
  .name {
    display: flex;
    max-width: 195px;
    height: 60px;
  }
  .h_title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 6px;
  }
  .h_icon {
    flex: none;
    width: 24px;
    height: 24px;
    margin-right: 6px;
    background-image: url("https://web-data.zmlearn.com/image/bVi9UFgseFfLHjRcEt5Jty/avatar_company%402x.png");
  }
}

.layout_wrap {
  :global {
    .ant-layout {
      height: 100%;
    }
  }
}

.icon_img {
  width: 16px;
  height: 16px;
}
