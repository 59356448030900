@import "./mixin.scss";
@import "./theme.scss";
.screen-pc {
  width: $screenPc;
}
.screen-pc-min {
  width: 100%;
  min-width: $screenPc;
}
.flex-between {
  @include flex-justify(space-between);
}
.flex-center {
  @include flex-justify(center);
}
.btn {
  cursor: pointer;
}
.space-btn {
  margin-right: 8px;
}
.login-btn {
  @include set-font(22px, #fff, 30px, 500);
  font-family: HelveticaNeue-Medium, HelveticaNeue;
  background: linear-gradient(316deg, #ff6536 0%, #f32735 100%);
  border-radius: 27px;
  letter-spacing: 1px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  cursor: pointer;
  position: relative;
  &[data-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &[data-loading="true"] {
    background: linear-gradient(316deg, #ff6536 0%, #f32735 100%);
    opacity: 0.5;
    cursor: not-allowed;
    &::after {
      position: absolute;
      content: "";
      width: 23px;
      height: 24px;
      border: 1px dotted #fff;
      border-radius: 50%;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &:hover {
    background: linear-gradient(316deg, #ff8159 0%, #ff4d59 100%);
    box-shadow: 0px 5px 14px 0px rgba(255, 46, 23, 0.16);
    border-radius: 27px;
  }
  &:active {
    background: linear-gradient(316deg, #ff6536 0%, #f32735 100%)
      rgba(0, 0, 0, 0.15);
  }
}

/* 全局modal 弹窗样式 */
.g-custom-modal {
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-body {
    padding: 0 42px 44px;
  }
}

/* 全局content 内容高度 去除底部翻页器 */
.g-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.g-bg-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
